<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ item?.id ? 'Warehouse item editing' : 'Adding a new warehouse item' }}</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="warehouseItem_category_modal"
                    v-model="selectedCategory"
                    @change="changeCategory"
                    :clearSearchData="!visible"
                    :options="warehouseCategories"
                    :loading="!warehouseCategories"
                    optionLabel="name"
                    :filter="true"
                    :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="warehouseItem_category_modal">{{ $t('Category') }}</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="warehouse_item_code_modal" :class="{'p-invalid' : submitted && !itemData.code}" @input="changeCode" v-model.trim="itemData.code" autocomplete="off"/>
          <label for="warehouse_item_code_modal">{{ $t('Code') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.code">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="warehouse_item_name_modal" :class="{'p-invalid' : submitted && !itemData.name}" v-model.trim="itemData.name" autocomplete="off"/>
          <label for="warehouse_item_name_modal">{{ $t('Name') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.name">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="warehouseItem_qty_modal"
                       :locale="computedNumberInputLocale"
                       showButtons
                       v-model="itemData.qty"
                       mode="decimal"
                       :min="0"
                       :max="999999"
                       :disabled="disableQtyEditing || ($store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant)"
                       :minFractionDigits="is_decimal ? 2 : null"
                       :maxFractionDigits="is_decimal ? 2 : null"
                       autocomplete="off"/>
          <label for="warehouseItem_qty_modal">Qty</label>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !itemData.qty">{{ $t('Required field') }}</small>-->
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="warehouseItem_unit_modal"
                    :class="{'p-invalid' : submitted && !selectedUnit}"
                    v-model="selectedUnit"
                    :preventFilter="true"
                    :options="units">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="warehouseItem_unit_modal">Unit<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedUnit">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="warehouseItem_purchase_price_modal"
                       :locale="computedNumberInputLocale"
                       @input="updatePurchasePrice(itemData, $event.value)"
                       v-model="itemData.purchase_price"
                       mode="decimal"
                       :min="0"
                       :minFractionDigits="2"
                       :maxFractionDigits="5"
                       autocomplete="off"/>
<!--          <label for="warehouseItem_purchase_price_modal">{{ $t('Purchase price') }}<span class="warning-color">*</span></label>-->
          <label for="warehouseItem_purchase_price_modal">{{ $t('Purchase price') }}</label>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !itemData.purchase_price">{{ $t('Required field') }}</small>-->
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="warehouseItem_sell_price_modal"
                       :locale="computedNumberInputLocale"
                       :inputClass="{'p-invalid' : submitted && !itemData.sell_price}"
                       v-model="itemData.sell_price"
                       mode="decimal"
                       :min="0"
                       :minFractionDigits="2"
                       :maxFractionDigits="5"
                       autocomplete="off"/>
          <label for="warehouseItem_sell_price_modal">Sell price<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.sell_price">{{ $t('Required field') }}</small>
      </div>


<!--      DON'T DELETE! min and max quantity inputs-->
<!--      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputNumber id="warehouseItem_min_qty_modal" :locale="computedNumberInputLocale" v-model="itemData.min_qty" mode="decimal" :min="0" :minFractionDigits="is_decimal ? 2 : null" :maxFractionDigits="is_decimal ? 2 : null" autocomplete="off"/>-->
<!--          <label for="warehouseItem_min_qty_modal">Minimum stock qty</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputNumber id="warehouseItem_max_qty_modal" :locale="computedNumberInputLocale" v-model="itemData.max_qty" mode="decimal" :min="0" :minFractionDigits="is_decimal ? 2 : null" :maxFractionDigits="is_decimal ? 2 : null" autocomplete="off"/>-->
<!--          <label for="warehouseItem_max_qty_modal">Maximum stock qty</label>-->
<!--        </div>-->
<!--      </div>-->


      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-d-flex p-ai-center">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="warehouseItem_is_decimal_modal" v-model="is_decimal"/>
          </div>
          <label for="warehouseItem_is_decimal_modal" class="switch-label pointer">Qty can be decimal</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label">-->
<!--          <Textarea id="warehouseItem_comment_modal" v-model.trim="itemData.comment" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="warehouseItem_comment_modal">{{ $t('Comment') }}</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              v-model="itemData.comment"
                              :maxChars="commentMaxChars"
                              :id="'warehouse-item-comment'"
                              :label="$t('Comment')"/>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from "@/services/http.services";
import generateMixins from "@/mixins/generateMixins";
import httpMixins from "@/mixins/httpMixins";
import constants from "@/constants";
import formatMixins from "@/mixins/formatMixins";
import units from "@/translations/units";
import settings from "@/settings";

export default {
  mixins: [ generateMixins, httpMixins, formatMixins ],
  emits: ['close', 'update-items', 'update-item'],
  name: 'WarehouseItemModal',
  props: {
    disableQtyEditing: {
      type: Boolean,
      default: false,
    },
    item: Object,
    // categories: Array,
    visible: Boolean,
    selectedCategoryId: {
      type: [Number, null],
      default: null
    },
    modalLayer: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      // units: [
      //   {
      //     num: 1,
      //     label: {
      //       en: 'Piece',
      //       et: 'Tk',
      //       ru: 'Штука',
      //     }
      //   },
      //   {
      //     num: 2,
      //     label: {
      //       en: 'Meter',
      //       et: 'Meeter',
      //       ru: 'Метр',
      //     }
      //   }
      // ],
      commentMaxChars: 500,
      dataIsSending: false,
      disableSaveButton: false,
      is_decimal: false,
      selectedUnit: null,
      selectedCategory: null,
      isRecurring: false,
      useConsumable: false,
      itemData: {},
      submitted: false,
      constants,
      settings,
      warehouseCategories: null,
      units,
    }
  },
  watch: {
    // categories(value) {
    //   console.log(value)
    // },
    item(value) {
      this.itemData = {
        ...value,
        qty: value.qty ? +value.qty : 0,
        purchase_price: value.purchase_price ? +value.purchase_price : null,
        sell_price: value.sell_price ? +value.sell_price : null,
        min_qty: value.min_qty ? +value.min_qty : null,
        max_qty: value.max_qty ? +value.max_qty : null,
      }

      this.is_decimal = !!value.is_decimal;

      if (value.unit) {
        this.selectedUnit = this.units.find(unit => unit.num === value.unit)
      } else {
        this.selectedUnit = this.units.find(unit => unit.num === 1)
      }
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
        this.selectedCategory = null
        this.selectedUnit = null
        this.is_decimal = false
        // this.is_decimal = false
        this.warehouseCategories = null
      } else {
        this.getWarehouseCategories().then(() => this.selectCategory())
      }
    },
  },
  methods: {
    selectCategory() {
      // if (!this.itemData.warehouse_category_id) return
      const categoryId = this.itemData.warehouse_category_id ? +this.itemData.warehouse_category_id : this.selectedCategoryId ? this.selectedCategoryId : null
      if (!categoryId) return

      const selectedCategory = this.warehouseCategories?.find(category => category.id === categoryId)
      if (selectedCategory) {
        this.selectedCategory = selectedCategory
      }
    },
    async getWarehouseCategories() {
      try {
        const { data, status } = await httpClient('warehouse/get-categories?sort=sorting')
        if (status === 200 && data) {
          // this.warehouseCategories = Array.isArray(data) ? data : []
          this.warehouseCategories = data
        }
      } catch(err) {
        this.showError(err)
      }
    },
    changeCategory() { //Don't delete
      this.itemData.categoryWasChanged = this.itemData.warehouse_category_id !== this.selectedCategory?.id
    },
    changeCode(event) {
      this.itemData.raw_code = this.generateRawCode(event.target.value)
    },
    updatePurchasePrice(itemData, value) {
      if (value) {
        const warehouseMargin = this.$store.state.branchData.warehouse_margin
        const defaultWarehouseMargin = this.$store.state.branchData.warehouse_default_margin

        if (warehouseMargin) {
          const margins = JSON.parse(this.$store.state.branchData.warehouse_margin)
          const margin = margins.filter(margin => {
            return margin.from <= value && margin.to >= value
          })[0]
          const marginValue = margin ? margin.value : defaultWarehouseMargin ? defaultWarehouseMargin : null
          itemData.sell_price = value * ((marginValue / 100) + 1)
        }

      } else {
        itemData.sell_price = 0
      }
    },
    async saveItem() {
      this.submitted = true
      if (!this.itemData.name ||
          !this.itemData.code ||
          // !this.itemData.purchase_price ||
          !this.itemData.sell_price ||
          !this.selectedUnit ||
          this.itemData.comment && this.itemData.comment.length > this.commentMaxChars) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const warehouseItem = {
        id: this.itemData.id ?? null,
        warehouse_category_id: this.selectedCategory ? this.selectedCategory.id : null,
        code: this.itemData.code,
        raw_code: this.itemData.raw_code,
        name: this.itemData.name,
        // qty: this.itemData.qty,
        qty: this.is_decimal && this.itemData.qty ? this.formatDecimal(this.itemData.qty, false) : this.formatDecimal(this.itemData.qty, false, 0),
        purchase_price: this.itemData.purchase_price,
        sell_price: this.itemData.sell_price,
        unit: this.selectedUnit ? this.selectedUnit.num : null,
        min_qty: this.itemData.min_qty,
        max_qty: this.itemData.max_qty,
        is_decimal: this.is_decimal ? 1 : 0,
        comment: this.itemData.comment,
        categoryWasChanged: this.itemData.categoryWasChanged,
        isNew: false
      }

      if (this.itemData.id) {
        try {
          const { status } = await httpClient.post(`warehouse/update-item`, warehouseItem)
          // console.log(data)
          if (status === 200) {
            // this.itemData.isNew = false
            // this.$emit('update-items', this.itemData.id)
            // this.$emit('update-item', this.itemData)
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item', warehouseItem)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`warehouse/create-item`, warehouseItem)
          if (status === 201 && data) {
            // this.itemData.id = data
            this.itemData.id = data
            warehouseItem.id = data
            warehouseItem.isNew = true
            // this.itemData.isNew = true
            // this.$emit('update-items', this.itemData.id)
            // this.$emit('update-item', this.itemData)
            this.$emit('update-items', this.itemData.id)
            this.$emit('update-item', warehouseItem)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: this.modalLayer === 2 ? '15px' : 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: this.modalLayer === 2 ? '15px' : 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: this.modalLayer === 2 ? '15px' : 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>