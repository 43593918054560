<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ !itemData?.id ? $t('Adding a new car') : $t('Car editing') }}</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="make"
                          v-model="selectedMake"
                          :options="makes"
                          :loading="makesDataIsLoading"
                          optionLabel="name"
                          :clearSearchData="!visible"
                          dataKey="id"
                          :filter="true"
                          @change="changeMake"
                          :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="make">{{ $t('Make') }}</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="model"
                          v-model="selectedModel"
                          :options="models"
                          :loading="modelsDataIsLoading"
                          optionLabel="name"
                          :disabled="!selectedMake"
                          :clearSearchData="!visible || clearModelSearchData"
                          @filter="clearModelSearchData = false"
                          dataKey="id"
                          :filter="true"
                          :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="model">{{ $t('Model') }} <span v-if="models && models.length">({{ models.length }})</span></label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
<!--          @keypress="plateNumberOnKeyPress"-->
<!--          @input="plateNumberOnInput"-->
          <InputText id="plate_number"
                     :class="{'p-invalid' : submitted && !itemData.plate_number}"
                     @change="changePlateNumber"
                     @input="changePlateNumber"
                     @blur="changePlateNumber"
                     v-model.trim="itemData.plate_number"
                     autocomplete="off"/>
          <label for="plate_number">{{ $t('Plate number') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.plate_number">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="vin"
                     @change="changeVin"
                     @input="changeVin"
                     @blur="changeVin"
                     v-model="itemData.vin"
                     autocomplete="off"/>
          <label for="vin">{{ $t('VIN number') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="year" v-model="itemData.year" :useGrouping="false" :min="new Date().getFullYear() - 80" :max="new Date().getFullYear() " showButtons autocomplete="off"/>
          <label for="year">{{ $t('Year') }}</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="car-power" v-model="itemData.power" :useGrouping="false" showButtons autocomplete="off"/>
          <label for="car-power">{{ $t('Power') }} ({{ settings.measurements.power }})</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="car-engine-code"
                     v-model="itemData.engine_code"
                     autocomplete="off"/>
          <label for="car-engine-code">{{ $t('Engine code') }}</label>
        </div>
      </div>

<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputText id="drivetrain" v-model="itemData.drivetrain" autocomplete="off"/>-->
<!--          <label for="drivetrain">{{ $t('Drivetrain') }}</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputText id="fuel" v-model="itemData.fuel" autocomplete="off"/>-->
<!--          <label for="fuel">{{ $t('Fuel') }}</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputText id="transmission" v-model="itemData.transmission" autocomplete="off"/>-->
<!--          <label for="transmission">{{ $t('Transmission') }}</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputText id="power" v-model="itemData.power" autocomplete="off"/>-->
<!--          <label for="power">{{ $t('Power') }} ({{ $t(settings.measurements.power) }})</label>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea id="comment" v-model="itemData.comment" rows="3" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="comment">{{ $t('Comment') }}</label>-->
<!--        </div>-->
<!--      </div>-->

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <LimitedCharsTextarea :submitted="submitted"
                              v-model="itemData.comment"
                              :maxChars="commentMaxChars"
                              :id="'car-modal-comment'"
                              :label="$t('Comment')"/>
      </div>
      <div v-if="itemData.plate_number?.length" class="p-col-12 p-sm-12 p-md-12 p-lg-12">
        <CarDataLink :plateNumber="itemData.plate_number"/>
      </div>
    </div>


<!--    <div class="p-formgrid p-grid" v-if="foundCars">-->
<!--      <div class="p-field p-col">-->
<!--        <div class="warning-color">Внимание! Автомобиль с таким регистрационным номером уже существует.</div>-->
<!--        <div class="p-mt-1">Данные автомобиля:</div>-->
<!--        <div v-if="foundCars.make?.name" class="p-mt-1">Make: {{ foundCars.make?.name }}</div>-->
<!--        <div v-if="foundCars.model?.name" class="p-mt-1">Model: {{ foundCars.model?.name }}</div>-->
<!--        <div v-if="foundCars.plate_number" class="p-mt-1">Plate number: {{ foundCars.plate_number }}</div>-->
<!--        <div v-if="foundCars.year" class="p-mt-1">Year: {{ foundCars.year }}</div>-->
<!--        <div v-if="foundCars.year" class="p-mt-1">VIN: {{ foundCars.vin }}</div>-->
<!--      </div>-->
<!--    </div>-->

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>

  <CarExistsModal :visible="carExistsModal"
                  :carId="itemData?.id"
                  :items="foundCars"
                  @select-existing-car="selectExistingCar"
                  @create-new-anyway="createNewAnyway"
                  @close="closeCarExistsModal"/>
</template>

<script>
// import httpClient from '@/services/http.services'
import settings from '@/settings'
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import generateMixins from "@/mixins/generateMixins";
import CarExistsModal from "@/pages/cars/components/CarExistsModal";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import CarDataLink from "@/pages/cars/components/CarDataLink";

export default {
  mixins: [ showErrorsMixins, formatMixins, generateMixins ],
  components: { CarExistsModal, CarDataLink },
  emits: ['close', 'update-items', 'update-item'],
  name: 'CarModal',
  props: {
    userId: Number,
    item: Object,
    itemAddress: Object,
    visible: Boolean,
    // isFirstLayer: {
    //   type: Boolean,
    //   default: false
    // }
    modalLayer: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      commentMaxChars: 500,
      makesDataIsLoading: false,
      modelsDataIsLoading: false,
      clearModelSearchData: false,
      itemData: {},
      submitted: false,
      makes: null,
      selectedMake: null,
      models: null,
      selectedModel: null,
      settings,
      foundCars: [],
      carExistsModal: false,
      disableSaveButton: false,
      dataIsSending: false,
    }
  },
  watch: {
    // '$store.state.firstLayerIsOpened'(value) {
    //   console.log(value)
    //   if (!value) {
    //     if (this.carExistsModal) {
    //       this.closeCarExistsModal()
    //     }
    //   }
    // },
    // '$store.state.secondLayerIsOpened'(value) {
    //   console.log('secondLayerIsOpened', value)
    //   if (!value) {
    //     if (this.carExistsModal && this.isFirstLayer) {
    //       this.closeCarExistsModal()
    //     }
    //   }
    // },
    // '$store.state.thirdLayerIsOpened'(value) {
    //   console.log('thirdLayerIsOpened', value)
    //   if (!value) {
    //     if (this.carExistsModal && !this.isFirstLayer) {
    //       this.closeCarExistsModal()
    //     }
    //   }
    // },
    '$store.state.secondLayerIsOpened'(value) {
      if (this.modalLayer !== 1) return false
      if (!value) {
        if (this.carExistsModal) {
          this.closeCarExistsModal()
        }
      }
    },
    '$store.state.thirdLayerIsOpened'(value) {
      if (this.modalLayer !== 2) return false
      if (!value) {
        if (this.carExistsModal) {
          this.closeCarExistsModal()
        }
      }
    },
    item(value) {
      if (!value) return false
      this.createItemData(value)
      // this.itemData = {
      //   ...value,
      //   year: value.year ? +value.year : null,
      //   power: value.power ? +value.power : null
      // }
      // if (value.make) {
      //   // console.log(value.make)
      //   this.selectedMake = value.make
      //   this.changeMake()
      //   // this.getModels()
      // }
      // if (value.model) {
      //   // console.log(value.model)
      //   this.selectedModel = value.model
      // }
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
        // this.itemData = {}
        this.makes = null
        this.models = null
        this.selectedMake = null
        this.selectedModel = null
        this.foundCars = []
      } else {
        this.getMakes()
      }
      // this.getModels()
    },
  },
  methods: {
    createItemData(value) {
      this.itemData = {
        ...value,
        year: value.year ? +value.year : null,
        power: value.power ? +value.power : null
      }
      if (value.make) {
        this.selectedMake = value.make
        this.changeMake()
      }
      if (value.model) {
        this.selectedModel = value.model
      }
    },
    selectExistingCar(carData) {
      this.createItemData(carData)
    },
    changePlateNumber(event) {
      let value = event.target.value
      this.itemData.plate_number = this.generateInputData(value)
    },
    changeVin(event) {
      let value = event.target.value
      this.itemData.vin = this.generateInputData(value)
    },
    generateInputData(value) {
      if (value) {
        value = value.toUpperCase()
      }
      return this.generateRawCode(value)
    },
    createNewAnyway() {
      this.saveItem(null, true)
    },
    closeCarExistsModal() {
      this.carExistsModal = false
      // this.$store.commit('closeRequiredAppLayer')
      if (this.modalLayer === 2) {
        // if (this.$store.state.thirdLayerIsOpened) {
          this.$store.commit('toggleThirdLayer', false)
        // }
      } else if (this.modalLayer === 1) {
        // if (this.$store.state.secondLayerIsOpened) {
          this.$store.commit('toggleSecondLayer', false)
        // }
      }
    },
    // openCarExistModal() {
    //   this.carExistsModal = true
    //   // this.$store.commit('closeRequiredAppLayer')
    //   if (this.modalLayer === 2) {
    //     if (this.$store.state.thirdLayerIsOpened) {
    //       this.$store.commit('toggleThirdLayer', true)
    //     }
    //   } else if (this.modalLayer === 1) {
    //     if (this.$store.state.secondLayerIsOpened) {
    //       this.$store.commit('toggleSecondLayer', true)
    //     }
    //   }
    // },
    // changeMake() {
    //   if (this.selectedMake) {
    //     // this.itemData.make = this.selectedMake
    //     this.getModels()
    //   } else {
    //     this.models = null
    //   }
    //   this.selectedModel = null
    // },
    // plateNumberOnInput(event) {
    //   if (event.value) {
    //     this.itemData.plate_number = event.toUpperCase()
    //   }
    // },
    // plateNumberOnKeyPress(event) {
    //   if (this.isLetter(event.key) || (event.charCode >= 48 && event.charCode <= 57)) {
    //     return
    //   } else {
    //     event.preventDefault()
    //   }
    // },
    // isLetter(c) {
    //   return c.toLowerCase() != c.toUpperCase();
    // },
    changeMake() {
      if (!this.selectedMake) {
        this.selectedModel = null
        this.models = null
      } else {
        this.getModels()
        this.clearModelSearchData = true
        this.selectedModel = null
      }
    },
    async getMakes() {
      this.makesDataIsLoading = true
      try {
        const { status, data } = await httpClient('car/get-makes?sort=name')
        if (status === 200 && data) {
          this.makes = data
          // console.log(data)
          // if (this.itemData && this.itemData.car_make_id) {
          //   this.selectedMake = this.makes.filter(make => make.id === this.itemData.car_make_id)[0]
          //   this.itemData.make = this.selectedMake
          // }
          // this.getModels()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.makesDataIsLoading = false
      }
    },
    async getModels() {
      if (!this.selectedMake) return false
      this.modelsDataIsLoading = true
      try {
        const { status, data } = await httpClient('car/get-models?sort=name&makeId=' + this.selectedMake.id)
        if (status === 200 && data) {
          this.models = data.map(make => ({ id: make.id, name: make.name }))
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.modelsDataIsLoading = false
      }
    },
    async saveItem(obj, createNewAnyway = false) {
      this.submitted = true
      if (!this.itemData.plate_number ||
          this.itemData.comment && this.itemData.comment.length > this.commentMaxChars) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const car = {
        id: this.itemData.id ?? null,
        user_id: this.userId ?? null,
        car_make_id: this.selectedMake?.id ?? null,
        car_model_id: this.selectedModel?.id ?? null,
        plate_number: this.itemData.plate_number ?? null,
        vin: this.itemData.vin ?? null,
        engine_code: this.itemData.engine_code ?? null,
        year: this.itemData.year ?? null,
        power: this.itemData.power ?? null,
        comment: this.itemData.comment ?? null,
        make: this.selectedMake ?? null,
        model: this.selectedModel ?? null,
        isNew: false,
        createNewAnyway,
      }

      if (this.itemData.id) {
        try {
          const { status, data } = await httpClient.post(`car/update`, car)
          // console.log(data)
          if (status === 200 && data?.success) {
            if (data.found_cars) {
              this.carExistsModal = true
              this.$store.commit('openRequiredAppLayer')
              this.foundCars = data.found_cars
              // this.openCarExistModal()
              // console.log(data.found_cars)
            } else {
              this.$emit('update-items', this.itemData.id)
              this.$emit('update-item', car)
              this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
              this.close()
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`car/create`, car)
          // console.log(data)
          if (status === 201 && data?.success) {
            if (data.found_cars) {
              this.carExistsModal = true
              this.$store.commit('openRequiredAppLayer')
              this.foundCars = data.found_cars
              // this.openCarExistModal()
              // console.log(data.found_cars)
            } else {
              this.itemData.id = data.id
              car.id = data.id
              car.isNew = true
              this.itemData.isNew = true
              this.$emit('update-items', this.itemData.id)
              this.$emit('update-item', car)
              this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
              this.close()
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: this.modalLayer === 2 ? '15px' : 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: this.modalLayer === 2 ? '15px' : 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: this.modalLayer === 2 ? '15px' : 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>